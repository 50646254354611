import React from 'react';
import { BaseComponent } from '..';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import infoImg from '../../img/info.svg';

export default class UpdateContactForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      idxActiveCity: -1,
      isSelected: false,
      currCity: '',
    };
  }

  componentDidMount() {
    const ElNumberInputs = document.querySelectorAll('.update-form__field[type=number]');
    for (let i = 0; i < ElNumberInputs.length; i++) {
      ElNumberInputs[i].addEventListener('keypress', function (event) {
        if ((event.which !== 8 && event.which !== 0 && event.which < 48) || event.which > 57) {
          event.preventDefault();
        }
      });
    }
    if (this.props.city) this.setState({ currCity: this.props.city.name });
  }

  onCityInput = (value) => {
    this.setState({ currCity: value, isSelected: false });
    this.props.onSearchCity(value);
  };

  onSelectedCity = (ev) => {
    const city = ev.target.dataset.id.split('_');


    this.props.onSearchCity('');
    this.props.handleInput({
      target: {
        name: 'city',
        value: {
          id: parseInt(city[1]),
          name: city[0],
        },
      },
    });
    this.setState({ currCity: city[0], isSelected: true });
  };

  onKeyClicked = (e) => {
    if (!this.props.cityList) {
      if (e.which === 13) {
        this.props.handleInput({
          target: { name: 'city', value: null },
        });
      }
      return;
    }
    let idx = this.state.idxActiveCity;
    let prevIdx = 0;
    const len = this.props.cityList.length;
    if (e.which === 40 && idx < len - 1) {
      this.setState({ isSelected: false });
      prevIdx = idx;
      idx += 1;
    }

    if (e.which === 38 && idx > 0) {
      this.setState({ isSelected: false });
      prevIdx = idx;
      idx -= 1;
    }

    if (idx === len - 1) {
      idx = 0;
    }
    if (idx !== -1) {
      //remove active from prev city
      if (prevIdx !== -1 && this.props.cityList[prevIdx]) {
        document.querySelector(`.city-${this.props.cityList[prevIdx].id}`).classList.remove('active');
      }

      const currCity = this.props.cityList[idx];
      if (currCity) {
        const elCurrCity = document.querySelector(`.city-${currCity.id}`) ;
        elCurrCity.classList.add('active');
        document.querySelector('.city-list').scrollTop = elCurrCity.offsetTop;
        if (e.which === 13) {
          elCurrCity.click();
          return;
        }
      }

      this.setState({ idxActiveCity: idx });
    }
  };

  onToggleTooltip = (name, isHideBlur, action = 'focus') => {
    const tooltipBtn = document.querySelector(`#${name}-tooltip-btn`);
    const tooltip = document.querySelector(`#${name}-tooltip`) ;
    if (!tooltip) {
      tooltipBtn.click();
    } else if (isHideBlur) {
      tooltipBtn.click();
    }
  };

  render() {
    return (
      <div className="update-form__body"style={{marginTop:'20px'}} >
        <div className="form-row">
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.email || ''}
              type="email"
              name="email"
              id="email"
              maxLength={100}
              required
              className={`update-form__field ${this.props.email ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              disabled={this.props.isDisable}
              // disabled={this.props.isVerifyEmail}
              onBlur={(ev) => {
                this.props.onBlur(ev);
                this.onToggleTooltip('email', true);
              }}
              onFocus={() => {
                this.onToggleTooltip('email');
              }}
              autoComplete="off"
            />
            <label htmlFor="email" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_email')}
            </label>

            <ButtonToolbar>
              <OverlayTrigger
                trigger="click"
                key={'top1'}
                placement={'top'}
                overlay={<Tooltip id="email-tooltip">{this.label('update_contact_email_tooltip')}</Tooltip>}
              >
                <button
                  onMouseDown={() => {
                    this.onToggleTooltip('email', true);
                  }}
                  type="button"
                  id="email-tooltip-btn"
                  className="update-form__tool-tip"
                >
                  <img src={infoImg} />
                </button>
              </OverlayTrigger>
            </ButtonToolbar>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              type="number"
              value={this.props.mobile || ''}
              inputMode="numeric"
              pattern="\d*"
              name="mobile"
              id="mobile"
              disabled={this.props.isDisable}
              required
              className={`update-form__field ${this.props.mobile ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              onBlur={(ev) => {
                this.props.onBlur(ev);
                this.onToggleTooltip('mobile', true);
              }}
              onFocus={() => {
                this.onToggleTooltip('mobile');
              }}
            />
            <label htmlFor="mobile" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_mobile')}
            </label>
            <ButtonToolbar>
              <OverlayTrigger
                trigger="click"
                key={'top2'}
                placement={'top'}
                overlay={<Tooltip id="mobile-tooltip">{this.label('update_contact_mobile_tooltip')}</Tooltip>}
              >
                <button
                  onMouseDown={() => {
                    this.onToggleTooltip('mobile', true);
                  }}
                  type="button"
                  id="mobile-tooltip-btn"
                  className="update-form__tool-tip"
                >
                  <img src={infoImg} />
                </button>
              </OverlayTrigger>
            </ButtonToolbar>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.phone || ''}
              name="phone"
              id="phone"
              type="number"
              inputMode="numeric"
              pattern="\d*"
              disabled={this.props.isDisable}
              className={`update-form__field ${this.props.phone ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="phone" className="update-form__label">
              {this.label('personal_phone')}
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 update-form__form-group city-contianer">
            <input
              onKeyDown={this.onKeyClicked}
              name="city"
              id="city"
              disabled={this.props.isDisable}
              required
              className={`update-form__field ${this.props.city ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.onCityInput(ev.target.value);
                this.setState({ idxActiveCity: -1 });
              }}
              value={this.state.currCity}
              onFocus={() => {
                if (this.state.currCity) {
                  this.setState({ isSelected: true });
                }
              }}
              onBlur={(e) => {
                if (!this.state.isSelected) {
                  this.onCityInput('');
                  this.props.onSearchCity('');
                  this.props.handleInput({
                    target: { name: 'city', value: null },
                  });
                }
              }}
              autoComplete="off"
            />
            <label htmlFor="city" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_city')}
            </label>
            <ul
              className={`city-list ${this.props.cityList.length > 10 ? 'height-list' : !this.props.cityList.length ? 'empty-list' : ''
                }`}
            >
              {this.props.cityList &&
                this.props.cityList.map((city) => {
                  return (
                    <li
                      data-id={`${city.name}_${city.id}`}
                      onClick={(ev) => {
                        this.onSelectedCity(ev);
                      }}
                      onMouseDown={(ev) => {
                        this.onSelectedCity(ev);
                      }}
                      className={`city city-${city.id}`}
                      key={`${city.name}-${city.id}`}
                    >
                      {city.name}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.street || ''}
              type="text"
              name="street"
              disabled={this.props.isDisable}
              id="street"
              maxLength={50}
              required
              className={`update-form__field ${this.props.street ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="street" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_street')}
            </label>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.houseNumber || ''}
              type="number"
              name="houseNumber"
              disabled={this.props.isDisable}
              id="houseNumber"
              inputMode="numeric"
              pattern="\d*"
              required
              className={`update-form__field ${this.props.houseNumber ? 'move-top' : ''}`}
              onChange={(ev) => {
                if (ev.target.value.length < 11) {
                  this.props.handleInput(ev);
                }
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="houseNumber" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_houseNo')}
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.apartment || ''}
              type="number"
              name="apartment"
              id="apartment"
              inputMode="numeric"
              pattern="\d*"
              disabled={this.props.isDisable}
              className={`update-form__field ${this.props.apartment ? 'move-top' : ''}`}
              onChange={(ev) => {
                if (ev.target.value.length < 11) {
                  this.props.handleInput(ev);
                }
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="apartment" className="update-form__label">
              {this.label('personal_apptNo')}
            </label>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.houseEntrance || ''}
              name="houseEntrance"
              id="houseEntrance"
              className={`update-form__field ${this.props.houseEntrance ? 'move-top' : ''}`}
              maxLength={1}
              disabled={this.props.isDisable}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="houseEntrance" className="update-form__label">
              {this.label('house_entrance')}
            </label>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.zip || ''}
              type="number"
              name="zip"
              id="zip"
              inputMode="numeric"
              disabled={this.props.isDisable}
              pattern="\d*"
              className={`update-form__field ${this.props.zip ? 'move-top' : ''}`}
              onChange={(ev) => {
                if (ev.target.value.length < 8) {
                  this.props.handleInput(ev);
                }
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="zip" className="update-form__label">
              {this.label('personal_postcode')}
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.mailbox || ''}
              type="number"
              name="mailbox"
              disabled={this.props.isDisable}
              id="mailbox"
              inputMode="numeric"
              pattern="\d*"

              className={`update-form__field ${this.props.mailbox ? 'move-top' : ''}`}
              onChange={(ev) => {

                if (ev.target.value.length < 11) {
                  this.props.handleInput(ev);
                }
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
            <label htmlFor="mailbox" className="update-form__label">
              {this.label('personal_postbox')}
            </label>
          </div>


          <div className="col-12 update-form__form-group" />
          <div className="col-12 update-form__form-group" />
        </div>
{/* 
        {
          !this.props.isDisable &&(
          <div className="checkbox_style_footer_update_details"
            style={{ marginRight: "-10px" }}
          >
            <input
              checked={this.props.mothlyRevNotice || false}
              type="checkbox"
              name="date"
              disabled={this.props.isDisable}
              id="date"
              className="yalin-checkbox__input"
              onChange={(ev) => {
                console.log({ ev });
                this.props.handleInput({
                  target: { name: 'mothlyRevNotice', value: ev.target.checked },
                });
              }}
            />
            <label htmlFor="date" className="yalin-checkbox__label">
              מאשר/ת קבלת הודעת SMS בדבר מועד עדכון היתרות באתר
            </label>
          </div>
       ) } */}
      


      </div>
    );
  }
}
