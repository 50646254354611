import { ClearCacheApi } from '../api'; // Assuming the API is already set up

const STORAGE_KEY = 'APP_VERSION';

export const checkAppVersion = async (setState, storageKey) => {
    const storageKeyHelper = storageKey ? storageKey : STORAGE_KEY;
    try {
        const meta = await ClearCacheApi.checkVersion();
        const newVersion = meta?.data?.version;
        const currentVersion = localStorage.getItem(storageKeyHelper);
        if (currentVersion !== newVersion) {
            if (currentVersion) {
                setState({isLatestVersion:false});
            } else {
                localStorage.setItem(storageKeyHelper, newVersion);
            }
            window.location.reload();
            clearAppCache(newVersion, storageKeyHelper);
            setState({ isLatestVersion: true });
            localStorage.setItem(storageKeyHelper, newVersion);
        } else {
            setState({
                isLatestVersion: true,
                latestVersion: newVersion,
            });
        }

        setState({
            loading: false,
            latestVersion: newVersion,
            isLatestVersion: true
        });

    } catch (error) {
        console.error('Error fetching version:', error);
        setState({ loading: false, isLatestVersion: true });
    }
};

// Function to clear cache
const clearAppCache = (version, storageKey) => {
    window.localStorage.setItem('refresh', null);
    if ('caches' in window) {
        window.caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
                window.caches.delete(cacheName);
            });
        });
    }
    localStorage.setItem(storageKey, version);
    const refresh = window.localStorage.getItem('refresh');
    if (refresh === null || refresh === 'null') {
        window.location.reload();
        window.localStorage.setItem('refresh', '1');

    }
};

