

import { BaseSecuredApi } from "./BaseSecuredApi";



class Actions extends BaseSecuredApi {

    constructor() {
        super("FormWizard");
    }

    get(data) {
        return this.POST({
            data: {
                formTypeId: data,
                nextStage: 0,
                formStatus: 1
            },
            action: "new",
        });
    }

}

export const ActionsApi = new Actions();