import React from 'react';
import jQuery from 'jquery';

var $=window.$
const validateForm = (form, rules, onSubmit, messages) => {
  $(form).validate({
    rules,
    messages,
    submitHandler: function (form, event) {
      onSubmit(event);
    },
  });
};

const isValidTypeFile = (name) => {
  const splitName = name.split('.');
  let type = splitName[splitName.length - 1];
  switch (type.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'jpeg':
    case 'png':
    case 'pdf':
      return true;
  }
  return false;
};

const optional = (element, value) => {
  return !element.hasAttribute('require') && !value;
};

$.validator.addMethod(
  'phone',
  function (value, element) {
    value = value.replace(/\(|\)|\s+|-/g, '');
    if (!value.length) return true;
    return (value.length === 9 || value.length === 10) && value.match(/^[0-9-+]+$/);
  },
  $.validator.messages.number
);

$.validator.addMethod(
  'mobile',
  function (value, element) {
    value = value.replace(/\(|\)|\s+|-/g, '');
    if (!value.length) return true;
    return value.length === 10 && value.match(/^[0][5][0-9]{8}$/);
  },
  'ניתן למלא מספר טלפון נייד בלבד הכולל 10 ספרות'
);

$.validator.addMethod(
  'letterswithbasicpunc',
  function (value, element) {
    return optional(element, value) || /^[a-z\u0590-\u05fe\-.,()'"\s]+$/i.test(value);
  },
  'רק אותיות בבקשה'
);

$.validator.addMethod(
  'hebletterswithbasicpunc',
  function (value, element) {
    return optional(element, value) || /^[\u0590-\u05fe\-.,()'"\s]+$/i.test(value);
  },
  'רק אותיות בעברית בבקשה'
);

$.validator.addMethod(
  'lettersonly',
  function (value, element) {
    return optional(element, value) || /^[\u0590-\u05fe]+$/i.test(value);
  },
  'רק אותיות בבקשה'
);

$.validator.addMethod(
  'letterswithnumber',
  function (value, element) {
    return !/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/i.test(value);
  },
  'יש להזין מספרים ואותיות בלבד'
);

$.validator.addMethod(
  'file',
  function (fileName, element) {
    let isType = isValidTypeFile(fileName);
    let isSize = element.files[0].size > 2 * 1024 * 1024;
    return isType && !isSize;
  },
  'ניתן להעלות קבצים בפורמט jpeg, gif, jpg, png, pdf במשקל עד 2 מגה.'
);

$.validator.addMethod(
  'loginusername',
  function (value, element) {
    return /^[a-z0-9.]+$/i.test(value);
  },
  'יש להזין רק אותיות באנגלית ומספרים'
);

$.validator.addMethod(
  'hebrewonly',
  function (value, element) {
    return optional(element, value) || /^[ \u0590-\u05fe'`"-]*$/i.test(value);
  },
  'יש להזין רק אותיות בעברית'
);

$.validator.addMethod(
  'numericonly',
  function (value, element) {
    return optional(element, value) || /^[0-9]+$/i.test(value);
  },
  'יש להזין מספרים בלבד'
);

$.validator.addMethod(
  'zip',
  function (value, element) {
    return optional(element, value) || value.length === 7;
  },
  'יש להזין מיקוד בן 7 ספרות'
);

$.validator.addMethod(
  'date',
  function (value, element) {
    return /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i.test(value);
  },
  'יש להזין תאריך במבנה dd/mm/yyyy'
);

$.validator.addMethod(
  'email',
  function (value, element) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
  },
  document.documentElement.clientWidth < 992 ? 'יש להזין כתובת דוא"ל תקינה' : 'יש להזין כתובת דואר אלקטרוני בצורה תקינה'
);

$.validator.addMethod(
  'personalId',
  function (value, element) {
    if (value !== null && value.length > 1) {
      if (value.indexOf('99') === 0) {
        const checkValue = value.replace('99', 'K');
        return /^[A-PR-WYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/i.test(checkValue);
      }
      if (value.indexOf('88') === 0) return true;
      if (parseInt(value) > 0) {
        var id = value;

        if (id.length > 9) return false;

        var arr = [1, 2, 1, 2, 1, 2, 1, 2, 1];
        var idArr = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        var idSplit = id.split('');
        var count = 8;

        var i;
        for (i = idSplit.length - 1; i >= 0; i--) {
          idArr[count] = parseInt(idSplit[i]);
          count--;
        }

        var res = 0;
        for (i = 0; i < 9; i++) {
          var tmpRes = arr[i] * idArr[i];

          if (tmpRes > 9) tmpRes = 1 + (tmpRes % 10);

          res = res + tmpRes;
        }

        if (res % 10 <= 0) return true;
      }
    }
    return false;
  },
  'אנא הזן ת.ז תקינה'
);

$.validator.addMethod(
  'otp',
  function (value, element) {
    return /^[0-9]{6}$/i.test(value);
  },
  'יש להזין מספרים בלבד'
);

$.validator.addMethod(
  'password',
  function (value, element) {
    return true;
  },
  $.validator.messages.number
);

class FormWithValidation extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    const rules = this.props.rules ? {} : null;
    const subMsgs = {};
    if (this.props.rules) {
      this.props.rules.forEach((x) => {
        rules[x.input] = {};
        x.validators.forEach((y) => {
          rules[x.input][y] = true;
        });
      });
      const subRules = {};
      this.props.rules.forEach((x) => {
        subRules[x.input] = {};
        x.validators.forEach((y) => {
          subRules[x.input][y] = true;
          subRules[x.input].name = y;
          if (x.error) {
            subRules[x.input].error = x.error;
          }
        });
      });
      for (let key in subRules) {
        if (subRules[key].error) {
          subMsgs[key] = { [subRules[key].name]: subRules[key].error, required: 'השדה הזה הינו שדה חובה' };
        }
      }
    }

    validateForm(this.form.current, rules || null, this.props.onSubmit, subMsgs);
  }

  render() {
    return (
      <form ref={this.form} className={this.props.className}>
        {this.props.children}
      </form>
    );
  }
}

export default FormWithValidation;

