import React from 'react';
import BaseComponent from '../BaseComponent';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';



export default class ResponsiveTable extends BaseComponent {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.init();
  }

  initialized = false;
  tableRef;
  collapseButton = $("<button class='row-toggle' title='show the row'></button>");
  collapsedCells = null;

  init() {
    if (!this.initialized) {
      this.initialized = true;

      this.onResize();

      window.addEventListener('resize', this.onResize.bind(this));
      window.addEventListener('orientationchange', this.onResize.bind(this));
    }
  }

  onResize() {
    if (this.tableRef) {
      const ele = $(this.tableRef.current);
      if (ele) {
        this.tableHandler(ele);
        this.rowTrigger(ele);
      }
    }
  }

  componentDidMount() {
    this.onResize();
  }

  componentDidUpdate() {
    this.onResize();
  }

  componentWillUnmount() {
    if (this.initialized) {
      window.removeEventListener('resize', this.onResize.bind(this));
      window.removeEventListener('orientationchange', this.onResize.bind(this));
    }
  }

  tableHandler(table) {
    if (table.length > 0) {
      if ($(window).width() < 992) {
        table
          .children('div:not(.thead)')
          .find('.tr')
          .each((i, ele) => {
            if ($(ele).children('.row-toggle').length <= 0) {
              $(ele).prepend(this.collapseButton.clone());
            }
            var url = window.location.href.toLowerCase();

            if (
              (url.indexOf('/balance') !== -1 && url.indexOf('accountstatus') === -1) ||
              url.indexOf('/requests') !== -1 ||
              url.indexOf('/recent-actions') !== -1
            ) {
              this.collapsedCells = $(ele).children('.td').slice(2, -1);

              if (url.indexOf('/balance') !== -1 && url.indexOf('accountstatus') === -1) {
                $(".th:contains('יתרה')").css('display', 'block');
              }
              if (url.indexOf('/requests') !== -1) {
                $(".th:contains('מספר חשבון')").css('display', 'block');
                $(".th:contains('סטטוס בקשה')").css('display', 'block');
                this.collapsedCells = $(ele).children('.td').slice(3, -1);
              }
              if (url.indexOf('/recent-actions') !== -1) {
                $(".th:contains('מספר חשבון')").css('display', 'block');
                $(".th:contains('סכום')").css('display', 'block');
                this.collapsedCells = $(ele).children('.td').slice(3, -1);
              }
            } else {
              this.collapsedCells = $(ele).children('.td').slice(1, -1);
            }
            //this.collapsedCells = $(ele).children(".td").slice(1, -1);
            this.collapsedCells.wrapAll(
              '<div class="collapse collapsed-td-container"><div class="collapsed-td"></div></div>'
            );
            var ths = $('.tr').children('.th:not(:last-child):not(:first-child)');
            if ($(window).width() < 992) {
              if (
                (url.indexOf('/balance') !== -1 && url.indexOf('accountstatus') === -1) ||
                url.indexOf('/requests') !== -1 ||
                url.indexOf('/recent-actions') !== -1
              ) {
                ths = $('.tr').children('.th:not(:last-child):not(:first-child):not(:nth-child(2))');
                if (url.indexOf('/requests') !== -1) {
                  ths = $('.tr').children('.th').slice(3, -1);
                }
                if (url.indexOf('/recent-actions') !== -1) {
                  ths = $('.tr').children('.th').slice(3, -1);
                }
              } else {
                ths = $('.tr').children('.th:not(:last-child):not(:first-child)');
              }
            }

            this.collapsedCells.each((index, cellEle) => {
              const cellLabelText = $(ths[index]).text();
              const cellLabel = $('<label>' + cellLabelText + '</label>');
              $(cellEle).prepend(cellLabel);
            });
          });
      } else {
        this.collapsedCells = $('.collapsed-td-container .td');
        this.collapsedCells.unwrap().unwrap();
        table.find('.td label').remove();
        table.find('.row-toggle').remove();
        table.children('div:not(".thead")').find('.tr').find('.td-button').show();
      }
    }
  }

  rowTrigger(table) {
    let rowTrigger = null;
    $(table)
      .off('click')
      .on('click', '.row-toggle', function () {
        rowTrigger = $(this);
        ($(this).closest('.tr').find('.collapse')).collapse('toggle');
      });

    $(table)
      .off('show.bs.collapse')
      .on('show.bs.collapse', '.collapsed-td-container', function () {
        rowTrigger.addClass('row-toggle--open');
      });

    $(table)
      .off('hide.bs.collapse')
      .on('hide.bs.collapse', '.collapsed-td-container', function () {
        rowTrigger.removeClass('row-toggle--open');
      });

    /* Open row No. __ */
    // $(table).find(".row-toggle").eq(0).trigger("click");
  }

  render() {

    console.log("{this.props.table.rows",this.props.table);
    
    return this.props.table ? (
      <div className={this.props.name + '-table-block'}>
        <div className={'table ' + this.props.name + '-table'} ref={this.tableRef}>
          {this.props.table.caption && (
            <div className="caption">
              <h3 className={this.props.name + '-table__title'}>{this.props.table.caption.title}</h3>
              <div className={this.props.name + '-table__sum number'}>{this.props.table.caption.substitle}</div>
            </div>
          )}
          {this.props.table.title ? <div className="table-caption">{this.props.table.title}</div> : null}
          <div className="thead">
            <div className="tr">
              {this.props.table.headers.map((header, index) => (
                <div className="th" key={index}>
                  {this.label(header)}
                </div>
              ))}
            </div>
          </div>
          <div className="tbody">
            {this.props.table.rows.map((row, rowIndex) => (
             
              <div className="tr" key={rowIndex}>
                {row.cells &&
                  row.cells.map((cell, cellIndex) => (
                    <div
                      className={'td' + (cell.valueType ? ' ' + cell.valueType : '') + ' ' + cell.class}
                      key={cellIndex}
                    >
                      {cell.valueType === 'td-button' ? (
                        <NavLink to={cell.url} className="link-details">
                          {cell.value}
                        </NavLink>
                      ) : (
                        <span className={cell.valueType === 'number' ? cell.valueType : ''}>
                          {cell.valueType === 'array'
                            ? cell.value.map((v, i) => <div key={i}>{v}</div>)
                            : cell.value}
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
          {this.props.table.footRows ? (
            <div className="tfoot">
              {this.props.table.footRows.map((row, rowIndex) => (
                <div className="tr" key={rowIndex}>
                  {row.cells &&
                    row.cells.map((cell, cellIndex) => (
                      <div className={'td' + (cell.valueType ? ' ' + cell.valueType : '') + ' ' + cell.class} key={cellIndex}>
                        {cell.valueType === 'td-button' ? (
                          <NavLink to={cell.url} className="link-details">
                            {cell.value}
                          </NavLink>
                        ) : (
                          <span className={cell.valueType === 'number' ? cell.valueType : ''}>{cell.value}</span>
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          ) : null}
          <style>
            {'.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'}
          </style>
        </div>
        {this.props.table.comment ? (
          <pre className={this.props.name + '-table-block__table-notice discFont'}>{this.props.table.comment}</pre>
        ) : null}
        {this.onResize()}
      </div>
    ) : null;
  }
}
