import { BaseSecuredApi } from './BaseSecuredApi';

class CustomerLetter extends BaseSecuredApi {
  constructor() {
    super('CustomerLetter');
  }

  get(data) {
    return this.POST({
      action: '',
      data,
    });
  }


  delete(letterId) {
    return this.GET({
      action: `delete/${letterId}`,
    });
  }


  deleteAll() {
    return this.GET({
      action: 'delete/viewed'
    })
  }

  getUnReadNumber() {
    return this.POST({
      action: 'statistics'
    })
  }

  Download(letterId) {
    return this.DownloadBlob({
      action: 'pdf',
      data: { letterId: letterId }
    }).catch((err) => {
      console.log(err);

      return err;
    });;
  }
}

export const CustomerLetterApi = new CustomerLetter();
