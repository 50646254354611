import React from 'react';
import { BaseComponent } from '..';
import { CustomDate } from '../Macros';



export default class UpdatePersonalForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownOpen: false,
    };
  }

  toggleDropDown = () => {
    if(!this.props.isDisable){
      this.setState({ isDropDownOpen: !this.state.isDropDownOpen });

    }
  };
  isValidTypeFile = (file) => {
    const { name, size } = file;
    const limitM = 2;
    if (size > limitM * 1024 * 1024) return false;
    const splitName = name.split('.');
    let type = splitName[splitName.length - 1];
    switch (type.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'jpeg':
      case 'png':
      case 'pdf':
        return true;
      default:
        return false;
    }
  };

  render() {
    const { maritalStatusOptions } = this.props.metadata;
    let maritalStatus = maritalStatusOptions[this.props.maritalStatus] || { text: '' };
    const regularTzFile = this.props.files.regular ? this.props.files.regular : null;
    const biometricTzFile = this.props.files.biometric.tz ? this.props.files.biometric.tz : null;
    const biometricBackTzFile = this.props.files.biometric.backTz ? this.props.files.biometric.backTz : null;
    return (
      
      <div className="update-form__body" >
        <div className="form-row">
          <div className="col-12 update-form__form-group ">
            <input
              value={this.props.firstName || ''}
              type="text"
              name="firstName"
              id="firstName"
              maxLength={50}
              required
              className={`update-form__field ${this.props.firstName ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
              disabled={this.props.isDisable}
            />
            <label htmlFor="firstName" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_first')}
            </label>
          </div>
          <div className="col-12 update-form__form-group">
            <input
              value={this.props.lastName || ''}
              type="text"
              name="lastName"
              id="lastName"
              maxLength={50}
              required
              className={`update-form__field ${this.props.lastName ? 'move-top' : ''}`}
              onChange={(ev) => {
                this.props.handleInput(ev);
              }}
              onBlur={this.props.onBlur}
              autoComplete="off"
              disabled={this.props.isDisable}
            />
            <label htmlFor="lastName" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_last')}
            </label>
          </div>

          <div className="col-12 update-form__form-group">
            <input
              type="number"
              value={this.props.personalId}
              inputMode="numeric"
              pattern="\d*"
              name="personalId"
              id="personalId"
              className="update-form__field move-top number"
              disabled
            />
            <label htmlFor="personalId" className="update-form__label">
              {this.label('personal_ID')}
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 update-form__form-group">
            <div className={`update-form__field ${maritalStatus.text !== '' ? 'move-top' : ''}`} id="maritalStatus">
              <div
                onClick={this.toggleDropDown}
                className={`select-selected ${this.state.isDropDownOpen ? '' : 'hide'} 
                ${this.props.isDisable ?'disable-date' :''}`}
              
              >
                {maritalStatus && this.label(maritalStatus.text.trim())}
              </div>
              <div className={`select-items ${this.state.isDropDownOpen ? '' : 'hide' }`}>
                {maritalStatusOptions.map((option) => (
                  <div
                    className="item"
                    key={option.val}
                    onClick={() => {
                      this.toggleDropDown();
                      this.props.handleInput({ target: { name: 'maritalStatus', value: option.val } });
                    }}
                  >
                    {this.label(option.text.trim())}
                  </div>
                ))}
              </div>
            </div>
            <label htmlFor="maritalStatus" className="update-form__label">
              {this.label('personal_maritalStatus')}
            </label>
          </div>
          <div className="col-12 update-form__form-group">
            <label className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_gender')}
            </label>

            <label className={`update-form__radio-label`}>
              <span style={{ marginRight: '25px' }}>זכר</span>
              <input
                type="radio"
                value={1}
                name="gender"
                required
                onChange={(ev) => {
                  this.props.handleInput(ev);
                }}
                checked={this.props.gender === 1}
                disabled={this.props.isDisable}
              />
              <span className="checkmark" />
            </label>
            <label className={`update-form__radio-label`}>
              <span style={{ marginRight: '25px' }}>נקבה</span>
              <input
                type="radio"
                name="gender"
                value={2}
                required
                disabled={this.props.isDisable}
                onChange={(ev) => {
                  this.props.handleInput(ev);
                }}
                checked={this.props.gender === 2}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className={"col-12 update-form__form-group "}>
            <CustomDate handleInput={this.props.handleInput} date={this.props.birthDate} isDisable={this.props.isDisable} />
            <label htmlFor="birthDate" className="update-form__label">
              <span style={{ color: 'red' }}>*</span>
              {this.label('personal_dob')}
            </label>
          </div>
        </div>
        {this.props.isFormChange && (
          <div>
            <span>איזו תעודת זהות יש ברשותך?</span>
            <div>
              <label className="update-form__radio-label">
                <span style={{ marginRight: '25px' }}>{this.label('update_personal_biometric')}</span>
                <input
                  type="radio"
                  name="isBiometricId"
                  required
                  onChange={(ev) => {
                    this.props.handleIDRadio(ev);
                  }}
                  checked={this.props.isBiometricId}
                  disabled={this.props.isDisable}
                />
                <span className="checkmark" />
              </label>

              <label className="update-form__radio-label">
                <span style={{ marginRight: '25px' }}>{this.label('update_personal_regular')}</span>
                <input
                  type="radio"
                  disabled={this.props.isDisable}
                  name="isBiometricId"
                  required
                  onChange={(ev) => {
                    this.props.handleIDRadio(ev);
                  }}
                  checked={!this.props.isBiometricId}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="">
              {!this.props.isBiometricId && (
                <div className="update-form__upload">
                  <span className="text">
                    <span style={{ color: 'red' }}>*</span>
                    {this.label('update_personal_image_attachment')}
                  </span>
                  {regularTzFile ? (
                    <>
                      <span className="text">{regularTzFile.name}</span>
                      <span onClick={() => this.props.onchangeFile(null)} className="text remove">
                        x
                      </span>
                    </>
                  ) : (
                    <div className="update-form__upload-container">
                      <input
                        name="regular-tz"
                        disabled={this.props.isDisable}
                        onChange={(ev) => {
                          ev.currentTarget.blur();
                          if (ev.target.files && this.isValidTypeFile(ev.target.files[0])) {
                            this.props.onchangeFile(ev.target.files[0]);
                          }
                        }}
                        required
                        id="regular-tz"
                        className="upload-input"
                        type="file"
                      />
                      <label htmlFor="regular-tz" className="upload-btn">
                        <span>+</span>
                        {this.label('update_personal_choose_file')}
                      </label>
                    </div>
                  )}
                </div>
              )}
              {this.props.isBiometricId && (
                <>
                  <div className="update-form__upload">
                    <span className="text">
                      <span style={{ color: 'red' }}>*</span>
                      {this.label('personal_ID')}
                    </span>
                    {biometricTzFile ? (
                      <>
                        <span className="text">{biometricTzFile.name}</span>
                        <span onClick={() => this.props.onchangeFile(null, 'tz')} className="text remove">
                          x
                        </span>
                      </>
                    ) : (
                      <div className="update-form__upload-container">
                        <input
                          name="biometric-tz"
                          id="biometric-tz"
                          onChange={(ev) => {
                            ev.currentTarget.blur();
                            if (ev.target.files && this.isValidTypeFile(ev.target.files[0])) {
                              this.props.onchangeFile(ev.target.files[0], 'tz');
                            }
                          }}
                          className="upload-input"
                          type="file"
                          required
                          disabled={this.props.isDisable}
                        />
                        <label htmlFor="biometric-tz" className="upload-btn">
                          <span>+</span>
                          {this.label('update_personal_choose_file')}
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="update-form__upload">
                    <span className="text">
                      <span style={{ color: 'red' }}>*</span>
                      {this.label('update_personal_back_image')}
                    </span>
                    {biometricBackTzFile ? (
                      <>
                        <span className="text">{biometricBackTzFile.name}</span>
                        <span onClick={() => this.props.onchangeFile(null, 'backTz')} className="text remove">
                          x
                        </span>
                      </>
                    ) : (
                      <div className="update-form__upload-container">
                        <input
                          onChange={(ev) => {
                            ev.currentTarget.blur();
                            if (ev.target.files && this.isValidTypeFile(ev.target.files[0])) {
                              this.props.onchangeFile(ev.target.files[0], 'backTz');
                            }
                          }}
                          className="upload-input"
                          type="file"
                          name="biometric-backTz"
                          id="biometric-backTz"
                          disabled={this.props.isDisable}
                          required
                        />

                        <label htmlFor="biometric-backTz" className="upload-btn">
                          <span>+</span>
                          {this.label('update_personal_choose_file')}
                        </label>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
