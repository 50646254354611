

import { BaseSecuredApi } from "./BaseSecuredApi";



class ClearCache extends BaseSecuredApi {

    constructor() {
        super("Version");
    }

   
  checkVersion() {
    return this.GET({
        action: 'getCurrentVersion'
      });

    }

}

export const ClearCacheApi = new ClearCache();