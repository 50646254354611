import { BaseSecuredApi } from "./BaseSecuredApi";



class Reports extends BaseSecuredApi {

    constructor() {
        super("Reports");
    }

    get(data) {
        return this.POST({
            data: data
        });
    }
    exportReportsPdf(reportId) {
        return this.POST({
            action: 'downloadPdf',
            data: { reportId: reportId }
        }).then(res => {
            if (res.downloadUrl) {
                res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
                return res;
            }
        });
    }


}

export const ReportsApi = new Reports();