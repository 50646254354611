import React from "react";
import { CmsService } from "../services";
import $ from "jquery";

export default class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.cmsPageAlias = null;
  }

  get cmsContent() {
    return this.cmsPageAlias &&
      CmsService.content &&
      CmsService.content.pages[this.cmsPageAlias]
      ? CmsService.content.pages[this.cmsPageAlias]
      : null;
  }

  formToJson(form) {
    const inputs = $(form).serializeArray();
    const result = {};
    inputs.forEach((n) => {
      result[n.name] = n.value;
    });
    return result;
  }

  deserializeForm(form) {
    return this.formToJson(form);
  }

  label(key) {
    var retVal = "";
    try {
      retVal = CmsService.content.labels[key.toLowerCase()] || key;
    } catch (e) {
      return key;
    }
    return retVal;
  }

  siteSetting(key) {
    var retVal = "";
    try {
      retVal = CmsService.content.pages.sitesettings[key] || key;
    } catch (e) {
      return key;
    }
    return retVal;
  }

  getYearsArray(comp) {
    var syb =  this.siteSetting('numberyearstoshow')
    if (syb === null) {
      if (comp === "SYBS") syb = "3";
      if (comp === "SYBT") syb = "3";
      if (comp === "SYBR") syb = "11";
    }

    var maxRevaluationDate = sessionStorage.getItem("maxRevaluationDate");
    var years = [];
    for (let i = 0; i < parseInt(syb); i++) {
      years.push(parseInt(maxRevaluationDate) - i);
    }
    return years;
  }
}
