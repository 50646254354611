import { UserDataService } from '../services';
import config from '../config';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const pdfHeaders = {
  Accept: 'application/pdf',
};

const htmlHeaders = {
  Accept: 'application/json',
};

export class BaseSecuredApi {
   static baseApiUrl = config.API_URL;
    _controller;
   subjects = {};

  constructor(controller) {
    this._controller = controller;
  }

   static getUrl = (controller, action) =>
    `${BaseSecuredApi.baseApiUrl}/api/${controller}/${action}`;
   static getRelativeUrl = (action) => `${action}`;
   static getHeaders() {
    return !UserDataService.isLoggedIn
      ? headers
      : {
          ...headers,
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
        };
  }
   static getFormHeaders() {
    return !UserDataService.isLoggedIn
      ? {}
      : {
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
        };
  }

   static getPdfHeaders() {
    return !UserDataService.isLoggedIn
      ? {}
      : {
          ...pdfHeaders,
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
          // responseType: 'blob',
        };
  }

   static getHtmlHeaders() {
    return !UserDataService.isLoggedIn
      ? {}
      : {
          ...htmlHeaders,
          Authorization: 'Bearer ' + UserDataService.currentUser.token,
          // responseType: 'blob',
        };
  }

   POST(
    request = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject();
    let result;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then((response) => {
        if (response.status === 204) {
          throw Error;
        }
        return (response ? (response.ok ? response.json() : { status: response.status }) : null) ;
      })
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

   POST_LOGIN(
    request= {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject();
    let result;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then((response) => {
        return (response ? (response.status === 200 ? response.json() : { status: response.status }) : null) ;
      })
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

   GET(
    request = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject();
    let result;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'GET',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then(
        (response) =>
          (response ? (response.ok ? response.json() : { status: response.status }) : null) 
      )
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

   FORM_POST(
    request= {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;

    const subject = new Subject();
    let result;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }

    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: UserDataService.isBlockedToAction
        ? !request.isHtml
          ? BaseSecuredApi.getPdfHeaders()
          : BaseSecuredApi.getHtmlHeaders()
        : BaseSecuredApi.getFormHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data,
    })
      .then((response) => {
        if (UserDataService.isBlockedToAction) {
          if (!request.isHtml) return (response && response.ok ? response.blob() : null) ;
          return (response ? (response.ok ? response.text() : null) : null);
        }
        return (response ? (response.ok ? response.json() : { status: response.status }) : null);
      })
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }

   DownloadBlob(
    request = {}
  ) {
    const action = request.action || '';
    const controller = request.controller || this._controller;
    const cache = request.cache === true;
    const subject = new Subject();
    let result;

    if (cache) {
      const key = action + '_' + (request.cacheKey || '');
      result = this.subjects[key];

      if (result) {
        return result.toPromise();
      }

      result = this.subjects[key] = subject.pipe(shareReplay());
    } else {
      result = subject.asObservable();
    }
    fetch(BaseSecuredApi.getUrl(controller, action), {
      method: 'POST',
      headers: BaseSecuredApi.getHeaders(),
      mode: 'cors',
      credentials: 'same-origin',
      body: request.data ? JSON.stringify(request.data) : null,
    })
      .then((response) => (response && response.ok ? response.blob() : null))
      .then((res) => {
        subject.next(res);
        subject.complete();
      })
      .catch((res) => subject.error(res));

    return result.toPromise();
  }
}
