import React from "react";
import { BaseComponent } from "..";
import { Redirect } from "react-router";
import { FormWithValidation } from "../Macros";
import { LoginApi } from "../../api";


export default class ResetPassword extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      secondPassword: null,
      isSending: false,
      redirectTo: null,
      error: ""
    };
  }

  componentDidMount() {
    LoginApi.validateResetPassword(this.token)
      .then(res => res && res.success)
      .catch(() => false)
      .then(success => {
        if (!success) {
          this.setState({
            redirectTo: "/customers/login/forgotPassword"
          });
        }
      });
  }

  get token() {
    return this.props.match.params.id ;
  }

  onSuccess() {
    alert("הסיסמה אופסה בהצלחה");
    this.setState({
      redirectTo: "/customers/login"
    });
  }

  rules = [
    {
      input: "password",
      validators: ["password"]
    },
    {
      input: "secondpassword",
      validators: ["password"]
    }
  ];

  handleSubmit(event) {
    this.setState({
      isSending: true
    });
    this.setState({ error: '' });

    LoginApi.resetPassword(this.token, this.state.password)
      .then(res => {
        if (res && res.success) {
          this.onSuccess();
        } else {
          this.setState({ error: res.message !== null ? res.message : "נסה שנית מאוחר יותר" });
        //   this.onFailed(res && res.message);
        }
      })
      .catch(res => {
        this.setState({ error: "נסה שנית מאוחר יותר" });
      })
      .finally(() => {
        this.setState({
          isSending: false
        });
      });

    event.preventDefault();
  }

  render() {
    if (this.state.redirectTo) {
        return <Redirect to={this.state.redirectTo}></Redirect>;
    }

    return (
      <div className="forgot-password-container">
        <h4 className="login-block__heading">איפוס סיסמה</h4>
        <h5 className="login-block__subheading">יש להזין את הסיסמה החדשה</h5>

        <FormWithValidation rules={this.rules} onSubmit={this.handleSubmit.bind(this)} className="login-form">
          <div className="login-form__body">
            <div className="login-form__form-group">
              <label htmlFor="password" className="login-form__label sr-only">
                סיסמה חדשה
              </label>
              <input type="text" name="password" id="password" placeholder="סיסמה חדשה" required className="login-form__field" onChange={e => this.setState({ password: e.target.value })} />
            </div>
            <div className="login-form__form-group">
              <label htmlFor="secondpassword" className="login-form__label sr-only">
                אימות סיסמה חדשה
              </label>
              <input type="text" name="secondpassword" id="secondpassword" placeholder="אימות סיסמה חדשה" required className="login-form__field" onChange={e => this.setState({ secondPassword: e.target.value })} />
            </div>
          </div>
          {this.state.error && <span className="error">{this.state.error}</span>}
          <div className="login-form__footer">
            <div className="login-form__terms yalin-checkbox"></div>
            <div className="login-form__submit-container">
              <button type="submit" className="login-form__submit" disabled={this.state.isSending}>
                המשך
              </button>
            </div>
          </div>
        </FormWithValidation>
      </div>
    );
  }
}
