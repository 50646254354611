import { BaseSecuredApi } from "./BaseSecuredApi";






class RequestsStatus extends BaseSecuredApi {

    constructor() {
        super("UserData");
    }

    get(data) {
        return this.POST({
            data,
            action: "Request/open/list",
        });
    }


    getFilesNames(folderId) {
        return this.GET({
            action: `Request/files/list/${folderId}`,
        });
    }

    DownloadFile(data) {
        return this.DownloadBlob({
            action: 'Request/file/open',
            data
        });
    }

}

export const RequestsStatusApi = new RequestsStatus();