import React from 'react';
import { BaseComponent } from '..';
import { Redirect } from 'react-router';
import { UserDataService } from '../../services';



export default class RemoteLogin extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null
        };
    }

    componentDidMount() {
        UserDataService.remoteLogin(this.token)
            .then(res => {
                this.setState({
                    redirectTo: res && res.success ? '/customers/home' : '/customers/login'
                });
            });
    }

    get token() {
        return this.props.match.params.token;
    }

    render() {

        if (this.state.redirectTo) {
            sessionStorage.setItem("imp", "1");
            return <Redirect to={this.state.redirectTo}></Redirect>;
        }

        return <div style={{ textAlign: "center", paddingTop: '2em' }}><img src="/img/loading.gif" alt="loading" /></div>;
    }
};