import { BaseSecuredApi } from "./BaseSecuredApi";



class ContactUs extends BaseSecuredApi {

    constructor() {
        super("ContactUs");
    }

    send(request) {
        return this.POST({
            action: "send", data: request
        });
    }

}

export const ContactUsApi = new ContactUs();