import { BaseSecuredApi } from './BaseSecuredApi';
import { UserDataService } from '../services';



class Login extends BaseSecuredApi {
  constructor() {
    super('Login');
  }

  async login(username, password, personalId) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN({
      data: {
        captchaToken,
        username,
        password,
        personalId,
      },
    });
  }

  async sendOtp(personalId, cellnumber, method) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN({
      action: 'loginViaOtp',
      data: {
        personalId,
        cellnumber,
        captchaToken,
        method,
      },
    });
  }

  async loginViaOtp(token, code) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN({
      action: 'loginViaOtp',
      data: {
        token,
        code,
        captchaToken,
      },
    });
  }

  async resendOtp(token, personalId, cellnumber, method) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST_LOGIN({
      action: 'resendOtp',
      data: {
        token,
        captchaToken,
        personalId,
        cellnumber,
        method,
      },
    });
  }

  sendResetPassword(personalId, cellnumber) {
    return this.POST({
      action: 'sendResetPassword',
      data: {
        personalId,
        cellnumber,
      },
    });
  }

  async sendGetUsername(personalId, dateofbirth, accnum) {
    const { token: captchaToken } = await UserDataService.getReCaptchaToken();
    return this.POST({
      action: 'sendGetUsername',
      data: {
        personalId,
        dateofbirth,
        accnum,
        captchaToken,
      },
    });
  }

  validateResetPassword(token) {
    return this.POST({
      action: 'validateResetPassword',
      data: {
        token,
      },
    });
  }

  resetPassword(token, newPassword) {
    return this.POST({
      action: 'resetPassword',
      data: {
        token,
        newPassword,
      },
    });
  }

  remoteLogin(token) {
    return this.POST({
      action: 'remoteLogin',
      data: {
        token,
      },
    });
  }
}

export const LoginApi = new Login();
