import config from "../config";



class Cms {
   static getUrl = (controller, action) =>
    `${config.API_URL}/api/${controller}/${action}`;

   async GET(action, data) {
    const response = await fetch(
      Cms.getUrl("Cms", action) +
        "?" +
        Object.keys(data)
          .map((key) => {
            return (
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent((data)[key])
            );
          })
          .join("&"),
      {}
    );
    return (await response.json()) ;
  }

  content() {
    return this.GET("content", {});
  }
}

export const CmsApi = new Cms();
