

import { BaseSecuredApi } from './BaseSecuredApi';




class Approvals extends BaseSecuredApi {
    constructor() {
        super('CustomerLetter')
    }


    getForm161Data(){
        return this.GET({
            action: '161',
          });
    }

    getAppendixFData(data){
        return this.POST({
            action: 'AppendixF',
            data
        })
        
    }

    getDepositData(data){
        return this.POST({
            action:'Deposit',
            data
        })

    }

    createNewAppendix(data){
        return this.DownloadBlob({
            action: 'AppendixF/new',
            data
        }).catch((err)=>{
            console.log(err);
            return err
            
        })
    }

    createNewDeposit(data){
        return this.DownloadBlob({
            action:'Deposit/new',
            data
        }).catch((err)=>{
            return err
        })
    }

    createNew161(data){
        return this.DownloadBlob({
            action: '161/new',
            data
        }).catch((err)=>{
            console.log(err);
            return err
            
        })
    }



    
}


export const ApprovalsApi = new Approvals();