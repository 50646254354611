import { BaseSecuredApi } from "./BaseSecuredApi";



class Balance extends BaseSecuredApi {

    constructor() {
        super("Balance");
    }

    get() {
        return this.POST({
            cache: true
        });
    }

    exportExcel() {
        return this.POST({
            action: 'exportExcel'
        }).then(res => {
            if (res.downloadUrl) {
                res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
                return res;
            }
        });
    }
    exportPdf() {
        return this.POST({
            action: 'exportPdf'
        }).then(res => {
            if (res.downloadUrl) {
                res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
                return res;
            }
        });
    }

}

export const BalanceApi = new Balance();