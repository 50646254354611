import React from 'react';
import { BaseComponent } from '..';

export default class Liquidity extends BaseComponent {

    render() {
        return <div className="pane-content pane-content--liquidity">
            <section className="data-section data-section--liquidity">
                <div className="data-section__header data-section__header--liquidity">
                    <h3 className="data-section__title">מועד נזילות</h3>
                </div>
                <div className="liquidity-table-block">
                    <div className="liquidity-table">
                        <div className="thead">
                            <div className="tr">
                                <div className="th">סוג יתרה</div>
                                <div className="th">סכום</div>
                                <div className="th">מועד נזילות</div>
                            </div>
                        </div>
                        <div className="tbody">
                            <div className="tr">
                                <div className="td"></div>
                                <div className="td"></div>
                                <div className="td"></div>
                            </div>
                        </div>
                        <div className="tfoot">
                            <div className="tr">
                                <div className="td"></div>
                                <div className="td"></div>
                                <div className="td"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>;
    }
}
