import React from 'react';
import { Route } from 'react-router';


const TopDucks = class TopDucks extends React.Component {
    render = () => {
        const isHome = this.props.location.pathname === '/customers/home/dashboard';
        const containerClass = isHome ? "main-overlay" : ("top-img" + (this.props.small ? " top-img--small-ducks" : ""));
        return <div aria-hidden="true" className={containerClass}>
            <img src="/img/ducks-halo.png" alt="ducks" className={isHome ? "main-overlay__ducks" : ""} />
        </div>
    };
};

export default class ExportedTopDucks extends React.Component {
    render = () => <Route render={renderProps => <TopDucks {...renderProps} {...this.props} />} />
}