import React from 'react';
import { UserDataService } from '../../services';
import { BaseComponent } from '..';
import { LoginApi } from '../../api';
import { FormWithValidation } from '../Macros';
import { Form } from 'react-bootstrap';
import $ from 'jquery';




export default class LoginViaOtp extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isLoading: false,
      otpCode: '',
      error: '',
      method: 1,
    };
    this.startOtpListener = this.startOtpListener.bind(this);
  }

  sendOtpRules = [
    {
      input: 'sms-id',
      validators: ['personalId'],
    },
    {
      input: 'sms-tel',
      validators: ['phone'],
    },
    {
      input: 'sms-code',
      validators: ['otp'],
    },
  ];

  loginRules = [
    {
      input: 'sms-code',
      validators: ['otp'],
    },
  ];

   startOtpListener() {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal
      }).then(otp => {
        const _otpCode = (otp).code;

        if (_otpCode) {

          this.setState({ otpCode: _otpCode });
          // Automatically submit the form if OTP is received
          this.login();
        }
      }).catch(err => {
        if (err.name !== 'AbortError') {
          console.error('Error fetching OTP:', err);
        }
      });

      // Abort after 5 minutes
      setTimeout(() => ac.abort(), 5 * 60 * 1000);
    }
  }
  sendOtp() {
    if (!this.state.isLoading) {
      this.setState({ error: '', isLoading: true });
      if (this.state.personalId.indexOf('99') === 0) {
        this.setState({ personalId: this.state.personalId.replace('99', '') });
      }
      LoginApi.sendOtp(this.state.personalId, this.state.cellnumber, this.state.method)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.isRevaluationProcess) {
            this.setState({ error: this.label('err_revaluation') });
          } else if (res.status) {
            try {
              if (res.status.toString().substring(0, 1) === '5') {
                this.setState({ error: this.label('http_error_5xx') });
              } else {
                const errorLabel = this.label(`http_error_${res.status}`);
                this.setState({ error: errorLabel.includes('http_error_') ? 'שגיאה כללית שלא נמצאה' : errorLabel });
              }
            } catch (err) {
              console.log(err);
              this.setState({ error: 'השרת אינו זמין - שגיאה כללית' });
            }
          } else {
            this.setState({
              cellnumber4digits: res.result.cellnumber4Digits,
              otpToken: res.result.token,
              step: 2,
            });
            this.startOtpListener(); // Start listening for OTP
            $('#sms-code').focus();
            setTimeout(function () {
              $('input[name="sms-code"]').focus();
            }, 500);
          }
        })
    }
  }

  resendOtp(event) {
    event.preventDefault();

    if (!this.state.isLoading) {


      this.setState({ isLoading: true, error: '' });
      LoginApi.resendOtp(this.state.otpToken, this.state.personalId, this.state.cellnumber, this.state.method)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.status) {
            // ... (existing error handling code)
          }
          if (res.success) {
            this.setState({ otpToken: res.result.token });
            this.startOtpListener(); // Restart listening for OTP
          }
        })
    }
  }
  login() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true, error: '' });
      LoginApi.loginViaOtp(this.state.otpToken, this.state.otpCode)
        .then((res) => {
          this.setState({ isLoading: false });

          if (res.status) {
            try {
              if (res.status.toString().substring(0, 1) === '5') {
                this.setState({ error: this.label('http_error_5xx') });
              } else {
                const errorLabel = this.label(`http_error_${res.status}`);
                this.setState({ error: errorLabel.includes('http_error_') ? 'שגיאה כללית שלא נמצאה' : errorLabel });
              }
            } catch (err) {
              console.log(err);
              this.setState({ error: 'השרת אינו זמין - שגיאה כללית' });
            }
          } else if (res.success) {
            sessionStorage.setItem('isBlockedToAction', JSON.stringify(res.isBlockedToAction));
            sessionStorage.setItem('customerId', JSON.stringify(res.customerId));
            sessionStorage.setItem('zoomAnaliticsData', res.result.jsonData);
            sessionStorage.setItem("isGemelShowed",JSON.stringify(true));

            sessionStorage.setItem('margeMessageType', JSON.stringify(res.result.margeMessageType));
            sessionStorage.setItem('toDisplayCourseMargePopUp', JSON.stringify(res.result.toDisplayCourseMargePopUp));
            sessionStorage.setItem('isMergePpopupShow', "0");
            sessionStorage.setItem('showLetterPopup', JSON.stringify(res.result.displayLetterPopUp));

            sessionStorage.setItem('sumUnViewed', JSON.stringify(res.result.sumUnViewed));
            sessionStorage.setItem('sumLetter', JSON.stringify(res.result.sumLetter));
            sessionStorage.setItem('isShowPopup', "0")

            UserDataService.setUser(res.result.username, res.result.token);
            sessionStorage.setItem('hasLoan', JSON.stringify(res.result.hasLoan));
            this.props.onLoginSuccess();
          }
        })
    }
  }

  render() {
    return (
      <>
        <div className={'multi-step-form multi-step-form--step1' + (this.state.step === 1 ? ' step-active' : '')}>
          <FormWithValidation rules={this.sendOtpRules} onSubmit={this.sendOtp.bind(this)} className="login-form">
            <div className="login-form__body">
              <div className="login-form__form-group">
                <label htmlFor="sms-id" className="login-form__label sr-only">
                  תעודת זהות
                </label>
                <input
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  name="sms-id"
                  id="sms-id"
                  placeholder="תעודת זהות"
                  required
                  autoComplete="off"
                  onChange={(event) => {
                    this.setState({ error: '', personalId: event.target.value + '' });
                  }}
                  className="login-form__field"
                />
              </div>
              <div className="login-form__form-group">
                <label htmlFor="sms-tel" className="login-form__label sr-only">
                  טלפון נייד
                </label>
                <input
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  name="sms-tel"
                  id="sms-tel"
                  placeholder="טלפון נייד"
                  required
                  autoComplete="off"
                  onChange={(event) => {
                    this.setState({ error: '', cellnumber: event.target.value + '' });
                  }}
                  className="login-form__field"
                />
              </div>
            </div>
            <div className="radio-container">
              <div className="text-container">
                <span>אני רוצה לקבל קוד באמצעות:</span>
              </div>
              <div key="inline-radio" className="mb-3">
                <Form.Check
                  inline
                  checked={this.state.method === 1}
                  label="בהודעת SMS"
                  type="radio"
                  id="inline-radio-1"
                  onChange={() => this.setState({ method: 1 })}
                />
                <Form.Check
                  checked={this.state.method === 2}
                  inline
                  label="בהודעה קולית"
                  type="radio"
                  id="inline-radio-2"
                  onChange={() => this.setState({ method: 2 })}
                />
              </div>
            </div>

            {this.state.error && <span className="error">{this.state.error}</span>}

            <div className="login-form__footer">
              {this.state.isLoading ? (
                <div style={{ textAlign: 'center', flex: '1' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <>
                  <div className="login-form__terms yalin-checkbox">
                    <input
                      type="checkbox"
                      name="sms-confirm"
                      required
                      id="sms-confirm"
                      className="yalin-checkbox__input"
                    />
                    <label htmlFor="sms-confirm" className="yalin-checkbox__label">
                      אני מאשר/ת את
                      <a target="_blank" href="\#" id="otpTerms">
                        תנאי השימוש
                      </a>
                    </label>
                  </div>
                  <div className="login-form__submit-container">
                    <button type="submit" className="login-form__submit">
                      המשך
                    </button>
                  </div>
                </>
              )}
            </div>
          </FormWithValidation>
        </div>
        <div className={'multi-step-form multi-step-form--step2' + (this.state.step === 2 ? ' step-active' : '')}>
          <FormWithValidation rules={this.sendOtpRules} onSubmit={this.login.bind(this)} className="login-form">
            <div className="login-form__body">
              <p className="login-form__instruction">
                <span className="login-form__instruction-text">
                  קוד הזיהוי נשלח למכשיר הטלפון שמספרו <br />
                </span>
                <span className="login-form__instruction-number">XXX-XXX{this.state.cellnumber4digits}</span>
                <span className="login-form__instruction-text">יש להקליד את הקוד שהתקבל</span>
              </p>
              <div className="login-form__form-group">
                <label htmlFor="sms-code" className="login-form__label sr-only">
                  קוד
                </label>
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="\d*"
                  name="sms-code"
                  id="sms-code"
                  placeholder="הקלד קוד"
                  value={this.state.otpCode}
                  autoFocus
                  onChange={(event) => {
                    this.setState({ error: '', otpCode: event.target.value });
                  }}
                  required
                  minLength={6}
                  maxLength={6}
                  className="login-form__field"
                />
              </div>
            </div>
            {this.state.error && <span className="error">{this.state.error}</span>}
            <div className="login-form__footer login-form__footer--step2">
              {this.state.isLoading ? (
                <div style={{ textAlign: 'center', flex: '1' }}>
                  <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                </div>
              ) : (
                <>
                  <div className="login-form__terms">
                    לא קיבלתי קוד ?
                    <a href="#" onClick={(event) => this.resendOtp.call(this, event)}>
                      שלח שוב
                    </a>
                  </div>
                  <div className="login-form__submit-container">
                    <button type="submit" className="login-form__submit">
                      המשך
                    </button>
                  </div>
                </>
              )}
            </div>
          </FormWithValidation>
        </div>
      </>
    );
  }
}