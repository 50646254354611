import { BaseSecuredApi } from "./BaseSecuredApi"

class RecentActions extends BaseSecuredApi {

    constructor() {
        super("UserData");
    }

    get(data) {
        return this.POST({
            data,
            action: "Recent/Actions",
            cache: false
        });
    }

}

export const RecentActionsApi = new RecentActions();