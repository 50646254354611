import React, { Fragment } from 'react';
import MultiSelect from 'react-bootstrap-multiselect';

var $=window.$

export default class CustomMultiSelect extends React.Component {
  static index = 0;
  id = 'dropdown-custom-' + CustomMultiSelect.index++;
  firstUse = true;

  componentDidMount() {
    $('.test-multiselect').multiselect({
      numberDisplayed: 1,
      includeSelectAllOption: true,
      allSelectedText: 'הכל נבחר',
      selectAllNumber: true,
      selectAllText: 'הכל',
      nSelectedText: 'נבחרו',
      nonSelectedText: 'לא בוצעה בחירה'
    });

    if ($('.multiselect-native-select').length === 4) {
      $('.multiselect-native-select')[0].style.display = 'none'
      $('.multiselect-native-select')[2].style.display = 'none'
    }

    $('.multiselect-native-select .dropdown-toggle').each(function (i, o) {
      $(o)[0].click();
    });
  }


  render() {
    return (
      <Fragment>
        <MultiSelect data={this.props.options} multiple data-placeholder={this.props.label} />
        <select className="test-multiselect" multiple>
          {this.props.options &&
            this.props.options.map((o, i) => {
              return (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              );
            })}
        </select>
      </Fragment>
    );
  }
}