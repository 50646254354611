import { BaseSecuredApi } from './BaseSecuredApi';




class TaxReports extends BaseSecuredApi {
  constructor() {
    super('Reports');
  }

  get(data) {
    return this.POST({
      action: 'tax/list',
      data,
    });
  }
  exportReportsPdf(reportId) {
    return this.POST({
      action: 'downloadPdf',
      data: { reportId },
    }).then((res) => {
      if (res.downloadUrl) {
        res.downloadUrl = BaseSecuredApi.baseApiUrl + res.downloadUrl;
        return res;
      }
    });
  }
}

export const TaxReportsApi = new TaxReports();
