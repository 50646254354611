import { BaseSecuredApi } from './BaseSecuredApi';



class Credito extends BaseSecuredApi {
  constructor() {
    super('UserData/credit');
  }

  get() {
    return this.GET({
      action: 'info',
    });
  }
}

export const CreditoApi = new Credito();
