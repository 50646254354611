import React from "react";
import { findDOMNode } from "react-dom";
import { withRouter } from "react-router";
import { UserDataService } from '../../services';
import $ from "jquery";
import history from "../../history";





class ChartCircle extends React.Component {
  strokeWidth;
  constructor(props) {
    super(props);
    this.strokeWidth = this.props.strokeWidth || 28;
  }

  componentDidMount() {
    ($(findDOMNode(this))).tooltip({
      boundary: "scrollParent",
      trigger: "manual",
      html: true,
      template: '<div class="tooltip chart-tooltip" role="tooltip"><a href="/customers/home/balance/accountStatus/' + this.props.acct + '"><div class="arrow chart-tooltip__arrow"></div><div class="tooltip-inner chart-tooltip__content"></div></a></div>',
      title: '<p class="chart-tooltip__label">' + this.props.title.map((v, i) => v).join("<br/>") + '</p><p class="chart-tooltip__value">' + this.props.text + "</p>",
      offset: "0, 8"
    });
  }

  onMouseEnter(event) {
    $(event.currentTarget)
      .parent()
      .siblings(".circle-overlay")
      .removeClass("d-none");
    ($(event.currentTarget)).tooltip("show");
  }

  onMouseLeave(event) {
    $(event.currentTarget)
      .parent()
      .siblings(".circle-overlay")
      .addClass("d-none");
    ($(event.currentTarget)).tooltip("hide");
  }

  onMouseClick(event) {
    $(event.currentTarget)
      .parent()
      .siblings(".circle-overlay")
      .addClass("d-none");
    ($(event.currentTarget)).tooltip("hide");
    var x = $(event.currentTarget).attr("name");
    history.push("/customers/home/balance/accountStatus/" + x);
  }

  render() {
    return (
      <circle
        stroke={this.props.color}
        strokeWidth={this.strokeWidth}
        strokeDasharray={!this.props.ratio ? null : `${this.props.ratio * this.props.circleLength}, ${this.props.circleLength}`}
        strokeDashoffset={this.props.offest}
        strokeOpacity={this.props.strokeOpacity}
        transform={`rotate(180, ${this.props.chartSize / 2}, ${this.props.chartSize / 2})`}
        fill="none"
        cx={this.props.chartSize / 2}
        cy={this.props.chartSize / 2}
        r={this.props.chartRadius}
        className={this.props.className ? this.props.className : "circle__item" + (this.props.reset ? " circle-reset" : "")}
        pointerEvents={this.props.pointerEvents}
        style={{ transitionProperty: "all", transitionDuration: "0.75s", transitionTimingFunction: "ease", cursor: "pointer" }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onMouseClick.bind(this)}
        name={this.props.acct.toString()}
      >
        <title></title>
        <desc>{this.props.title}</desc>
      </circle>
    );
  }
}

class Chart extends React.Component {
  svgChartRef;
  labelRef;

  constructor(props) {
    super(props);
    this.state = { reset: true };
    this.svgChartRef = React.createRef();
    this.labelRef = React.createRef();
    if (props.colors !== null) this.defaultPallette = props.colors;
  }

  /* Default parameters */
  chartSize = 194;
  chartStrokeWidth = 28;
  chartRadius = this.chartSize / 2 - this.chartStrokeWidth / 2;
  chartCircleLength = Math.round(this.chartRadius * 2 * Math.PI);
  defaultPallette = ["#042e5a", "#675914", "#4f2404", "#154c85", "#907e28", "#853b00", "#7996b8", "#c5b050", "#d16304"];
  //defaultPallette = ["#042e5a", "#435f93", "#b2c8e1", "#f1ecd4", "#c5b050", "#907e28"];
  //defaultPallette = ["#907e28", "#c5b050", "#f1ecd4", "#b2c8e1", "#435f93", "#042e5a"];

  processChartText() {
    const visibleAreaWidth = 138;
    let chartCaption = this.labelRef.current;
    let chartCaptionFontSize = chartCaption.getAttribute("font-size");
    let initialCaptionLength = chartCaption.getComputedTextLength();

    let newChartCaptionFontSize = Number(chartCaptionFontSize.slice(0, chartCaptionFontSize.indexOf("px")));

    if (initialCaptionLength > visibleAreaWidth) {
      let newFontSize = Math.floor((visibleAreaWidth / initialCaptionLength) * newChartCaptionFontSize) + "px";
      chartCaption.setAttribute("font-size", newFontSize);
    }
  }

  animateCharts() {
    if (this.svgChartRef.current) {
      const $element = $(this.svgChartRef.current);
      const $window = $(window);

      if ($element.offset().top + $element.outerHeight() > $window.scrollTop() && $element.offset().top - $window.scrollTop() < $window.height()) {
        this.setState({ reset: false });
      } else if ($element.offset().top > $window.height() || $element.offset().top + $element.outerHeight() < $window.scrollTop()) {
        this.setState({ reset: true });
      }
    }
  }

  componentDidMount() {

    setTimeout(() => this.animateCharts.call(this), 100);
    window.addEventListener("scroll", this.animateCharts.bind(this));
    window.addEventListener("resize", this.animateCharts.bind(this));
    window.addEventListener("orientationchange", this.animateCharts.bind(this));
    this.processChartText();
  }

  componentDidUpdate() {
    this.processChartText();
  }

  render() {
    /* derived parameters */
    let chartValuesTotal = this.props.values.reduce(function (sum, value) {
      return sum + value.value;
    }, 0);

    let totalOffsetRatio = 0;
    return (
      <div className="chart-block">
        <svg viewBox={`0 0 ${this.chartSize} ${this.chartSize}`} width={this.chartSize} height={this.chartSize} className="svg-chart" ref={this.svgChartRef} style={{ display: this.props.label === "0" ? "none" : "block" }}>
          <style>{`.circle-reset {stroke-dasharray: 0 ${this.chartCircleLength}}`}</style>
          <text x="50%" y="55%" textAnchor="middle" fill="#636365" fontFamily="'Open Sans', sans-serif" fontSize="21px" letterSpacing="-0.015em" className="chart-text" ref={this.labelRef}>
            {this.props.label}
          </text>
          <circle stroke="#fff" strokeWidth={this.chartStrokeWidth} strokeOpacity="0.1" fill="none" cx={this.chartSize / 2} cy={this.chartSize / 2} r={this.chartRadius} className="circle__bg"></circle>
          <g>
            {this.props.values
              .filter(x => x.value > 0)
              .map((x, i) => {
                const currentRatio = x.value / chartValuesTotal;
                let color = this.props.colors && this.props.colors.length > i ? this.props.colors[i] : null;
                if (color === null) {
                  let colorIndex = i;
                  while (colorIndex >= this.defaultPallette.length) {
                    colorIndex -= this.defaultPallette.length;
                  }
                  color = this.defaultPallette[colorIndex];
                }
                const ele = <ChartCircle key={i} {...x} color={color} chartSize={this.chartSize} strokeWidth={this.chartStrokeWidth} ratio={currentRatio} circleLength={this.chartCircleLength} offest={-totalOffsetRatio * this.chartCircleLength} chartRadius={this.chartRadius} reset={this.state.reset} />;
                totalOffsetRatio += currentRatio;
                return ele;
              })}
          </g>
          <circle stroke="#000" strokeWidth={this.chartStrokeWidth} strokeOpacity=".3" fill="none" cx={this.chartSize / 2} cy={this.chartSize / 2} r={this.chartRadius} pointerEvents="none" className="circle-overlay d-none" />
        </svg>
        <h3 className="chart-block__heading" style={{ visibility: this.props.label === "0" || UserDataService.data.userRole === 3 ? "hidden" : "visible" }}>
          {this.props.title}
        </h3>
      </div>
    );
  }
}

export default withRouter(Chart);
