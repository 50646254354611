import React, { useState, useEffect } from "react";
import CustomSelect, { ICustomSelectItem } from "../../Macros/CustomSelect";
import { ApprovalsApi } from "../../../api/Approvals";
import { PopupService } from "../../Macros/Popup";



const Doc161 = ({ isDisplayMessage, isShow }) => {
    const [pdfFile, setPdfFile] = useState(null);
    const [data, setData] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [employerOptions, setEmployerOptions] = useState([]);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);

    useEffect(() => {
        const fetchEmployerData = async () => {
            setIsInitialLoading(true);
            try {
                const response = await ApprovalsApi.getForm161Data();
                if (response && response.length > 0) {
                    setData(response);
                    const options = response.map((item) => ({
                        label: item.employerName.toString(),
                        value: item.employerID.toString()
                    }));
                    setEmployerOptions([
                        { label: 'בחר ח.פ מעסיק', value: '' },
                        ...options
                    ]);
                    setIsDataReady(true);
                } else {
                    setEmployerOptions([]);
                    setIsDataReady(false);
                }
            } catch (error) {
                console.error('Error fetching employer data:', error);
                setEmployerOptions([]);
                setIsDataReady(false);
            } finally {
                setIsInitialLoading(false);
            }
        };

        fetchEmployerData();
    }, []);

    const saveValue = (value) => {
        setSelectedId(value);
    };

    const create161Letter = async () => {
        setIsLoading(true);
        console.log({ selectedId });

        const foundAccount = data.find((account) => account.employerID == selectedId);

        if (!foundAccount) {
            setIsLoading(false);
            return;
        }

        const personalId = JSON.parse(sessionStorage.getItem('user') || '{}').username;
        const requestData = {
            letterTypeId: 88,
            personalId,
            employerId: selectedId,
            fundCourseId: foundAccount.fundId
        };

        try {
            const res = await ApprovalsApi.createNew161(requestData);
            isDisplayMessage(true);

            if (res.size === 0) {
                setIsLoading(false);
            } else {
                const file = new Blob([res], { type: 'application/pdf' });
                setPdfFile(file);
                setShowDownloadButton(true);
            }
        } catch (error) {
            PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = () => {
        if (pdfFile) {
            const newTab = window.open('about:blank', '_blank');
            const url = URL.createObjectURL(pdfFile);
            if (newTab) {
                newTab.location.href = url;
            }
            setTimeout(() => URL.revokeObjectURL(url), 1000);
        }
    };

    if (isInitialLoading) {
        return (
            <div style={{ textAlign: "center", paddingTop: "2em" }}>
                <img src="/img/loading.gif" alt="loading" />
            </div>
        );
    }

    if (isShow) {
        return (
            <div>
                <div id="collapseFilter" className="collapse filter-block__collapse show">
                    <div className="data-filter__row">
                        <div className="data-filter__inputs">
                            <div className="data-filter__inputs-row">
                                <div className="col-auto data-filter__col">
                                    {"בקשתך התקבלה. האישור יוצג באזור האישי ב- \"דואר שלי\" תוך זמן קצר. במועד הפקת האישור, ישלח SMS למספר הטלפון הנייד המעודכן בילין לפידות"}
                                </div>

                            </div>
                            <div className="col-auto main-dashboard__btn-col">
                                <button
                                    type="button"
                                    className="main-dashboard__btn"
                                    onClick={handleDownload}
                                >
                                    לצפיה והורדה לחץ כאן
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!isDataReady) {
        return <div style={{ padding: '30px' }}>לא נמצאו תוצאות</div>;
    }

    return (
        <>
            {isLoading ? (
                <div style={{ textAlign: "center", paddingTop: "2em" }}>
                    <img src="/img/loading.gif" alt="loading" />
                </div>
            ) : (
                <div id="collapseFilter" className="collapse filter-block__collapse show">
                    <form id="tax-reports-filter-form" className="data-filter filter-block__form">
                        <div className="data-filter__row">
                            <div className="data-filter__inputs">
                                <div className="data-filter__inputs-row">
                                    <div className="col-auto data-filter__col">
                                        <CustomSelect
                                            id="df-date-nn"
                                            buttonClassName="custom-select__input"
                                            itemClassName="custom-select__item"
                                            options={employerOptions}
                                            onChange={saveValue}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-filter__buttons">
                            <div className="data-filter__buttons-row">
                                <div className="col-auto data-filter__buttons-col">
                                    <button
                                        disabled={selectedId === ''}
                                        type="button"
                                        className={`data-filter__btn ${selectedId === '' ? 'disableButton' : ''}`}
                                        onClick={create161Letter}
                                    >
                                        שלח
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default Doc161;