import { BaseSecuredApi } from './BaseSecuredApi';
import { UserDataService } from '../services';

class UserData extends BaseSecuredApi {
  constructor() {
    super('UserData');
  }

  get() {
    return this.POST();
  }

  renew() {
    return this.POST({
      action: 'renew',
    });
  }

  getPersonalDetails() {
    return this.POST({
      action: 'getPersonalDetails',
      // cache: true
    });
  }

  confirmBirthdayPopup(){
    return this.POST({
      action:"birthday/approved"
    })
  }

  submitPersonalDetails(data, isHtml) {
    if (UserDataService.isBlockedToAction) {
      return this.FORM_POST({
        action: 'Details/update/block',
        // action: "Details/update",
        data,
        isHtml,
      });
    }
    return this.FORM_POST({
      action: 'Details/update',
      // action: "Details/update",
      data,
    });
  }

  confirmPopUp(data) {
    return this.POST({
      action: 'Mailing/Confirm',
      cache: true,
      data,
    });
  }

  confirmMergePopup(data){
    return this.POST({
      action: "course/merge",
      data
    })
  }
  confirmLetterPopup(data) {
    return this.POST({
      action: "letters/Confirm",
      data
    })
  }
  getCity(data) {
    return this.POST({
      action: 'localities',
      controller: 'cms',
      data,
    });
  }

  getPdf(data) {

    return this.DownloadBlob({
      action: 'ExportAccountStreamPdf',
      data,
    }).then((res) => {
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([res], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blobObject, 'report.pdf');
      } else {
        var file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        // window.open(fileURL, '_blank');
        return fileURL;
      }
    });
  }

  getCheckLetterConsent(){
    console.log("checking")
    
    return this.POST({
      action:'CheckLetterConsent'
    })
  }

  confirmLetterApproved(){
    return this.POST({
      action:'letters/Confirm',
      data:{ isApproved: true}
    })
  }
}

export const UserDataApi = new UserData();
