import { BaseComponent } from '..';
class ErrorTypeMsg extends BaseComponent {
    getErrorMsg = (status) => {
        switch (status) {
            case 400:
            case 204:
            case 404:
                return this.label('err_login_not_correct')
            case 403:
                return this.label('err_login_user_locked')
            case 429:
                return this.label('err_login_too_many_times')
            case 500:
            case 503:
            case 504:
                return 'שגיאת שרת כללית - אנא נסה מאוחר יותר'  
            default: return this.label('err_login_try_later')
        }
    }
}

export const ErrorType = new ErrorTypeMsg({});