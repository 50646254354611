import React from 'react';
import BaseComponent from '../BaseComponent';
import CustomSelect from '../Macros/CustomSelect';
import { UserDataService } from '../../services';
import { TopDucks } from '../Macros';
import { BaseSecuredApi } from '../../api/BaseSecuredApi';
import $ from 'jquery';


class HeaderReports extends BaseSecuredApi {
  constructor() {
    super('Reports');
  }


  generateBlob(_reportId) {
    return this.DownloadBlob({
      action: 'ExportAccountStreamPdf',
      data: { reportId: _reportId, year: parseInt($('#selectedyear').text()) }
    }).then(res => {
      // @ts-ignore
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([res], { type: 'application/pdf' });
        // @ts-ignore
        window.navigator.msSaveBlob(blobObject, 'report.pdf');
      } else {
        var file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }
    });
  }
}

export const headerReports = new HeaderReports();



export default class Header extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingGenerate: false,
      isErrAccountPdf: false
    };
  }

  s = ' שח';
  accounts = UserDataService.data.accounts.map(x => {
    return {
      label: x.courses[0] ? x.courses[0] + ' - ' + x.balance.toLocaleString().toString() + this.s : x.balance.toLocaleString().toString(),
      value: x.id.toString()
    };
  });

  onAccountSelected(newValue) {
    this.props.onAccountSelected(newValue);
  }

  generateAccountPdf(reportId) {
    if (this.state.isLoadingGenerate) return;
    this.setState({ isLoadingGenerate: true, isErrAccountPdf: false  });
    headerReports
      .generateBlob(reportId)
      .then(() => {
        this.setState({ isLoadingGenerate: false});
      })
      .catch(() => {
        this.setState({ isLoadingGenerate: false, isErrAccountPdf: true });
      });
  }

  render() {
    return (
      <>
        <TopDucks />

        <div className="account-intro">
          <div className="content-wrapper">
            <div className="row account-intro__row">
              <div className="col-lg account-intro__col-text">
                <h1 className="account-intro__heading">{this.label('Account')}</h1>
                <ul className="account-intro__list">
                  {this.props.routes.map(x => (
                    <li key={x}>{x}</li>
                  ))}
                </ul>
                <h2 className="account-intro__subheading">
                  {this.label('balances_accNum')} <span className="number">{this.props.accountId}</span>
                </h2>
              </div>
              <div className="col-lg-auto">
                <div className="account-intro__control-row">
                  <div className="account-intro__select-wrapper">
                    <CustomSelect options={this.accounts} onChange={this.onAccountSelected.bind(this)} value={this.props.accountId} label="בחר חשבון אחר" />
                  </div>
                  <div className="account-intro__control-col">
                    <button type="button" className={`account-intro__download-btn${this.state.isLoadingGenerate ? ' loding-btn' : ''}`} onClick={() => this.generateAccountPdf(parseInt(this.props.accountId))}>
                      {this.state.isLoadingGenerate ? '' : 'הפק דוח'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isErrAccountPdf && <span className="account-intro__error error">לא ניתן להפיק את הדוח, אנא נסה שנית מאוחר יותר</span>}
          </div>
        </div>
        <style> {'@media (min-width:991px) { .account-intro__select-wrapper{width:380px !important} } .search-select .number{ direction:rtl }'} </style>
        <style> {'@media (max-width:990px) { .account-intro__control-row{display:inline-grid;width:100%} .account-intro__select-wrapper{width: 100%;margin-bottom: 20px;} .account-data__header{display:block} '} </style>
      </>
    );
  }
}
