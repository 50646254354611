import { BaseSecuredApi } from "./BaseSecuredApi";

class Dashboard extends BaseSecuredApi {

    constructor() {
        super("Dashboard");
    }

    get() {
        return this.POST({
            cache: true
        });
    }

}

export const DashboardApi = new Dashboard();