import React from 'react';
import BaseComponent from '../BaseComponent';

export class Footer extends BaseComponent {
    render() {
        return (
            <footer className="site-footer">
                <div className="container">
                    <div className="row" />
                </div>
            </footer>
        );
    }
}
