
import { BaseSecuredApi } from './BaseSecuredApi';




class ScanDocs extends BaseSecuredApi {
    constructor() {
        super('ScanDocs')
    }


    get(data){
        return this.POST({
            action: '',
            data,
          });
    }


    Download(docId) {
        return this.DownloadBlob({
          action: 'pdf',
          data: { docId: docId }
        }).catch((err) => {
          console.log(err);
    
          return err;
        });
      }

      delete(docId) {
        return this.GET({
          action: `delete/${docId}`,
        });
      }

      deleteAll() {
        return this.GET({
          action: 'delete/viewed'
        })
      }
    
}


export const ScanDocsApi = new ScanDocs();
