import React from 'react';
import { BaseComponent } from '..';
import { ActionsApi } from '../../api/Actions';
import history from '../../history';
import config from "../../config";
import { BalanceApi } from '../../api';


export default class AllActions extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            sent: false,
            loading: true,
            isHishtalmutExsist: false,
            isGemelExsist: false,
            contentReady: false
        };
    }

    hasLoan = JSON.parse(sessionStorage.getItem("hasLoan"));

    moveTopage = (formTypeId) => {
        this.setState({ loading: true });
        const type = formTypeId;

        formTypeId = formTypeId == 132 ? 3 : formTypeId;
        ActionsApi.get(formTypeId).then(res => {
            switch (type) {
                case 2: window.location.href = `${config.API_URL}/forms/transfer-between-courses/${res}`;
                    break;
                case 4: window.location.href = `${config.API_URL}/forms/beneficiaries/${res}`;
                    break;
                case 7: window.location.href = `${config.API_URL}/forms/billing-instruction/${res}`
                    break;
                case 15: window.location.href = `${config.API_URL}/forms/joining-investment/${res}`
                    break;
                case 8: window.location.href = `${config.API_URL}/forms/joining-hishtalmut/${res}`
                    break;
                case 3: window.location.href = `${config.API_URL}/forms/withdrawal/${res}`;
                    break;
                case 132: window.location.href = `${config.API_URL}/forms/hishtalmut/${res}`;
                    break
                default:
                    break;
            }
        });
    }

    componentDidMount() {
        BalanceApi.get().then(res => {
            const prodTypes = Array.from(new Set((res?.products).map((item) => item.prodType)));
            this.setState({
                isGemelExsist: prodTypes.includes(4),
                isHishtalmutExsist: prodTypes.includes(2),
                loading: false,
                contentReady: true
            });
        });
    }

    renderContent() {
        return (
            <>
                <div className='wrap-all-img-desc'>
                    <div className='wrap-img'>
                        <img onClick={() => { this.moveTopage(2) }} className='image-desc' src='img/mobile-action-img/Group_18848.png' alt="Transfer between courses" />
                        <img onClick={() => { history.push('/customers/home/personalDetails/update') }} className='image-desc' src='img/mobile-action-img/personal_update.png' alt="Update personal details" />
                        <img onClick={() => { this.moveTopage(4) }} className='image-desc' src='img/mobile-action-img/Group_18844.png' alt="Beneficiaries" />
                        {this.state.isGemelExsist && <img onClick={() => { this.moveTopage(3) }} className='image-desc' src='img/mobile-action-img/Group_18845.png' alt="Withdrawal" />}
                        {this.state.isHishtalmutExsist && <img onClick={() => { this.moveTopage(132) }} className='image-desc' src='img/mobile-action-img/Group_18846.png' alt="Hishtalmut" />}
                        <img onClick={() => { this.moveTopage(7) }} className='image-desc' src='img/mobile-action-img/Group_18852.png' alt="Billing instruction" />
                        <img onClick={() => { this.moveTopage(15) }} className='image-desc' src='img/mobile-action-img/Group_18851.png' alt="Joining investment" />
                        <img onClick={() => { this.moveTopage(8) }} className='image-desc' src='img/mobile-action-img/Group_18850.png' alt="Joining hishtalmut" />
                        {this.hasLoan !== false && <img className='image-desc' src='img/mobile-action-img/Group_18849.png' alt="Loan" />}
                    </div>
                </div>

                <div className='wrap-all-img-mobile'>
                    <div className='wrap-img'>
                        <img onClick={() => { this.moveTopage(2) }} className='image-mobile' src='img/mobile-action-img/Group_18775.png' alt="Transfer between courses" />
                        <img onClick={() => { history.push('/customers/home/personalDetails/update') }} className='image-mobile' src='img/mobile-action-img/personal_update_mobile.png' alt="Update personal details" />
                        <img onClick={() => { this.moveTopage(4) }} className='image-mobile' src='img/mobile-action-img/Group_18783.png' alt="Beneficiaries" />
                        {this.state.isGemelExsist && <img onClick={() => { this.moveTopage(3) }} className='image-mobile' src='img/mobile-action-img/Group_18784.png' alt="Withdrawal" />}
                        {this.state.isHishtalmutExsist && <img onClick={() => { this.moveTopage(132) }} className='image-mobile' src='img/mobile-action-img/Group_18785.png' alt="Hishtalmut" />}
                        <img onClick={() => { this.moveTopage(7) }} className='image-mobile' src='img/mobile-action-img/Group_18786.png' alt="Billing instruction" />
                        <img onClick={() => { this.moveTopage(15) }} className='image-mobile' src='img/mobile-action-img/Group_18788.png' alt="Joining investment" />
                        <img onClick={() => { this.moveTopage(8) }} className='image-mobile' src='img/mobile-action-img/Group_18789.png' alt="Joining hishtalmut" />
                        {this.hasLoan !== false && <img onClick={() => { window.location.href = `${config.API_URL}/forms/loan-request` }} className='image-mobile' src='img/mobile-action-img/Group_18790.png' alt="Loan" />}
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <>
                <div className="content-wrapper content-wrapper--sheet">
                    <div className="personal-intro">
                        <div className="content-wrapper">
                            <h1 className="personal-intro__heading">כל הפעולות</h1>
                        </div>
                    </div>
                    <div className='all-image-container'>
                        {this.state.loading ? (
                            <div style={{ textAlign: "center", paddingTop: "2em" }}>
                                <img src="/img/loading.gif" alt="loading" />
                            </div>
                        ) : this.state.contentReady ? (
                            this.renderContent()
                        ) : null}
                    </div>
                </div>

                <style>{" @media (max-width:991px) { .wrap-all-img-desc{display:none !important} } "}</style>
                <style>{" @media (min-width:991px) { .wrap-all-img-mobile{display:none !important} } "}</style>
            </>
        );
    }
}