import { BaseSecuredApi } from "./BaseSecuredApi";



class AccountStatus extends BaseSecuredApi {

    constructor() {
        super("AccountStatus");
    }

    getManagementFees(accountId) {
        return this.POST({
            action: "getManagementFees",
            data: { 
                accountId
            },
            cache: true, 
            cacheKey: accountId
        });
    }
    getStatus(accountId, year) {
        return this.POST({
            action: "getStatus", 
            data: { 
                accountId, 
                year: year 
            },
            cache: true,
            cacheKey: (accountId + '_' + year)
        });
    }
    getFundDetails(accountId) {
        return this.POST({
            action: "getFundDetails", 
            data: { 
                accountId
            },
            cache: true,
            cacheKey: accountId
        });
    }
    getTransactions(data) {
        return this.POST({
            action: "getTransactions", 
            data
        });
    }



}

export const AccountStatusApi = new AccountStatus();