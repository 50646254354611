import React from 'react';
import { BaseComponent } from '..';
import { TopDucks, CmsBannersInner } from '../Macros';
import { BalanceApi } from '../../api';
import ResponsiveTable from '../Macros/ResponsiveTable';
import $ from 'jquery';


export default class Balance extends BaseComponent {

    cmsPageAlias = 'balance';

    componentDidMount() {
        BalanceApi.get().then(res => {
            this.setState(res);
        });

        var cssC = '@media print {' +
            '.content-wrapper, .main-wrapper {width: 100%!important;} ' +
            '.concentration-table .tr {display:table-row } ' +
            '.concentration-table {border-bottom:1px solid rgba(209,216,245,.6);display: table } ' +
            '.concentration-table .caption {display:table-caption } ' +
            '.concentration-table .thead {display:table-header-group } ' +
            '.concentration-table .tbody {display:table-row-group } ' +
            '.concentration-table .tfoot {display:table-footer-group } ' +
            '.concentration-table .th {font-size:14px;border-top: 1px solid rgba(209,216,245,.6);display: table-cell;width: auto;padding: 28px 14px 7px 0 } ' +
            '.concentration-table .tbody {display: table-row-group;} ' +
            '.concentration-table .td, .concentration-table .th {display: table-cell;} ' +
            '.balance-data__actions-toggle.collapsed {display:none;}' +
            '.link-details{ visibility:hidden }' + 
            '.site-header__mobile-controls-list{display:none;}' +
            '}';
        var css = '<style>' + cssC + '</style>';
        //document.getElementsByTagName('head')[0].appendChild(css);
        $("head").append(css);
    }

    toTable(product) {        

        if ($(window).width() > 992) {            
            return {
                caption: {
                    title: 'ריכוז יתרות ב' + product.name,
                    substitle:  product.balance.toLocaleString()+ ' ₪'
                },
                headers: [this.label("balances_accNum"), this.label("balances_status"), this.label("balances_employer"), product.showSeniority ? 'ותק' : '', this.label("balances_accName"), this.label("balances_accBalance"), ''],
                rows: product.accounts.map((account) => ({
                    cells: [{
                        value: account.accountNumber,
                        valueType: 'number'
                    }, {
                        value: account.state
                    }, {
                        value: account.employer
                    }, {
                        value: account.seniority,
                        valueType: 'number'
                    }, {
                        value: account.fundAndRoute,
                        valueType: 'array'
                    }, {
                        value: '₪ ' +account.balance.toLocaleString(),
                        valueType: 'number'
                    },
                    {
                        value: this.label("balances_button"),
                        url: "/customers/home/balance/accountStatus/" + account.accountNumber,
                        valueType: 'td-button'
                    }]
                } ))
            };
        }
        else {            
            return {
                caption: {
                    title: 'ריכוז יתרות ב' + product.name,
                    substitle:  product.balance.toLocaleString()+ ' ₪'
                },
                headers: [this.label("balances_accNum"), this.label("balances_accBalance"), this.label("balances_status"), this.label("balances_employer"), product.showSeniority ? 'ותק' : '', this.label("balances_accName"), '', ''],
                rows: product.accounts.map((account) => ({
                    cells: [{
                        value: account.accountNumber,
                        valueType: 'number'
                    }, {
                            value:'₪ ' + account.balance.toLocaleString(),
                            valueType: 'number'
                        }, {
                        value: account.state
                    }, {
                        value: account.employer
                    }, {
                        value: account.seniority,
                        valueType: 'number'
                    }, {
                        value: account.fundAndRoute,
                        valueType: 'array'
                    },
                    {
                        value: this.label("balances_button"),
                        url: "/customers/home/balance/accountStatus/" + account.accountNumber,
                        valueType: 'td-button'
                    },
                    {
                    value: '',
                    valueType: ''
                    }]
                } ))
            } ;

        }

        
    }

    exportExcel() {
        BalanceApi.exportExcel().then(res => {
            if (res.downloadUrl) {
                document.location.href = res.downloadUrl;
            }
        });
    }

    exportPdf() {
        BalanceApi.exportPdf().then(res => {
            if (res.downloadUrl) {
                document.location.href = res.downloadUrl;
            }
        });
    }

    render() {
        return <>

            <TopDucks small />

            

            <div className="balance-intro">
                <div className="content-wrapper">
                    <div className="row balance-intro__row">
                        <div className="col-lg balance-intro__col-text">
                            <h1 className="balance-intro__heading">{this.cmsContent && this.cmsContent.title}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                    <div className="balance-data">

                        {this.state ?
                            <>
                                <div className="balance-data__header">
                                    <div className="balance-data__date-col">
                                        {this.state.updateDate && <p className="info-check-date">
                                            {this.label("account_date")}
                                    <time dateTime={this.state.updateDate} className="number">{this.state.updateDate}</time>
                                        </p>}
                                    </div>
                                    <div className="balance-data__actions-col">

                                        <button type="button" data-toggle="collapse" data-target="#collapseAction" aria-expanded="false" aria-controls="collapseAction" aria-label="toggle controls" className="balance-data__actions-toggle collapsed">
                                            <span className="caption">יצא טבלה</span>
                                        </button>

                                        <div id="collapseAction" className="collapse balance-data__actions-collapse">
                                            <ul className="action-buttons">
                                                <li className="action-buttons__item">
                                                    <button type="button" className="action-buttons__btn action-buttons__btn--print" onClick={() => window.print()}>הדפסה</button>
                                                </li>
                                                <li className="action-buttons__item">
                                                    <button type="button" onClick={() => this.exportExcel()} className="action-buttons__btn action-buttons__btn--excel">יצא לאקסל</button>
                                                </li>
                                                <li className="action-buttons__item">
                                                    <button type="button" onClick={() => this.exportPdf()} className="action-buttons__btn action-buttons__btn--pdf">יצא PDF</button>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="pane-content pane-content--balance">

                                    {this.state.products.map((product, productIndex) =>  <section className="data-section data-section--concentration" key={productIndex}>
                                        
                                        <ResponsiveTable name="concentration" table={this.toTable(product)} />
                                    </section>
                                    )}


                                    <section className="data-section data-section--concentration">
                                        <div className="total-concentration">
                                            <h3 className="total-concentration__title"> {this.label("account_total")}</h3>
                                            <div className="total-concentration__sum number"><span>₪</span>{this.state ? this.state.total.toLocaleString() : 0}</div>
                                        </div>
                                    </section>

                                    <CmsBannersInner banners={this.cmsContent && this.cmsContent.banners} />
                                    <style> {'.discFont{ font-size:14px; font-family:"Almoni DL AAA", sans-serif; white-space:pre-wrap }'} </style>
                                    <pre className="info-check-date account-table-block__table-notice discFont" style={{ marginTop: '30px', fontSize:"14px" }}>{this.label("balances_disc").replace('{date}', sessionStorage.getItem('updatedate'))}</pre>
                                </div>
                            </>
                            :
                            <div style={{ textAlign: 'center' }}>
                                <img src="/img/loading.gif" alt="loading" style={{ height: '50px' }} />
                            </div>}

                    </div>
                </div>
            </div>
            <style> {'.account-table-block__table-notice {display: block !important;} .grecaptcha-badge{ position:absolute;top:-10000px; display:none; } '} </style>
        </>;

        
    }
}
