import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BaseComponent } from '..';
import Header from './Header';
import LoginWrapper from './LoginWrapper';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';
import ResetPassword from './ResetPassword';
import RemoteLogin from './RemoteLogin';



export default class LoginLayout extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: null,
    };
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}></Redirect>;
    }

    return (
      <div className="site-wrapper">
        <Header />

        <main className="login-main">
          <div className="container-fluid">
            <div className="login-wrapper">
              <div className="login-block">
                <h1 className="login-block__heading">כניסה לאזור אישי</h1>
                <div className="login-block__form-block">
                  <Switch>
                    <Route exact path="/customers/login" component={LoginWrapper} />
                    <Route exact path="/customers/login/forgotPassword" component={ForgotPassword} />
                    <Route exact path="/customers/login/forgotUsername" component={ForgotUsername} />
                    <Route exact path="/customers/login/resetPassword/:id" component={ResetPassword} />
                    <Route exact path="/customers/login/remote/:token" component={RemoteLogin} />
                    <Redirect to="/"></Redirect>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
